<template>
    <div class="action-schedule">
        <div class="title f-bold" v-html="`약속잡기를 시작합니다`" />
        <div class="body" v-html="body" />
    </div>
</template>

<script>
export default {
    name: 'ActionScheduleStart',
    props: ['message'],
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        body() {
            return `${this.content.name} / ${this.$options.filters.asAge(this.content.birthday)} / ${
                this.content.job
            } 님과<br> 약속잡기를 시작합니다. `
        },
    },
}
</script>
